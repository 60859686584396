export default {
  success: {
    icon: 'account_circle',
    title: "Confirmation d'inscription",
    content:
      'Votre compte a bien été validé. <br> Vous pouvez maintenant vous connecter.',
    button: {
      text: 'Se connecter',
      route: 'Login',
    },
  },
  error: {
    icon: 'account_circle',
    title: '',
    content: '',
    button: {
      text: '',
      route: '',
    },
  },
};
